import { graphql, navigate } from 'gatsby';
import { ENABLE_UA_AUTO_REFILL } from 'gatsby-env-variables';
import GatsbyImage from 'gatsby-image';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import PageSection from 'ui-kit/page-section/page-section';

import { AutoRefillForm } from 'components/auto-refill-form';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';

import { refreshUnauthenticatedSession } from 'state/account/account.reducers';
import {
    autoRefillActions,
    autoRefillGetSecretKeyHashRoutine,
    autoRefillVerifyUserRoutine
} from 'state/auto-refill/auto-refill.reducer';
import {
    autoRefillSecurityTokenSelector,
    autoRefillUserBearerTokenSelector
} from 'state/auto-refill/auto-refill.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { VerifyUserResponse } from 'types/auto-refill';

import { getPhoneNumber } from 'util/globalVariables';
import { formatBirthDate } from 'util/string';

import './auto-refill.style.scss';

const AutoRefill = ({
    location,
    data
}: {
    location: Location;
    data: GatsbyTypes.AutoRefillGetLanguageAndImageDataQuery;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { autoRefillMan, backgroundImage } = data;
    const securityToken = useSelector(autoRefillSecurityTokenSelector);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const params = new URLSearchParams(location.search);
    const securityTokenParameter = params.get('securityToken');

    useEffect(() => {
        // if the feature is disabled - take the user to the 404 page
        if (!ENABLE_UA_AUTO_REFILL) {
            navigate('/404');
        }
    }, []);

    useEffect(() => {
        if (securityTokenParameter) {
            dispatch(autoRefillActions.setSecurityToken({ securityToken: securityTokenParameter }));
        } else {
            navigate('/link-expired?flow=auto-refill');
        }
    }, [dispatch, securityTokenParameter]);

    // Once security token is set in redux, get the secret key hash value to be used when authenticating (after verification)
    useEffect(() => {
        if (securityToken) {
            // To validate the security token we run a verifyUser request
            // which will inform us if the token is valid
            dispatch(
                autoRefillVerifyUserRoutine.trigger({
                    dateOfBirth: '',
                    zipcode: '',
                    onSuccess: () => {
                        // If token is not expired, get hash
                        dispatch(
                            autoRefillGetSecretKeyHashRoutine.trigger({
                                // If invalid hash, return expired
                                onFailure: () => {
                                    navigate('/link-expired?flow=auto-refill');
                                }
                            })
                        );
                    },
                    onFailure: () => {
                        // If token expired redirect to expired link
                        navigate('/link-expired?flow=auto-refill');
                    },
                    onMaxNumAttemptsReached: () => {
                        navigate('/verification-failed?flow=auto-refill');
                    }
                })
            );
        }
    }, [dispatch, securityToken]);

    const handleShowErrorModal = useCallback(
        (error: VerifyUserResponse) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: 'danger',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                    ),
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={error.messageText}
                            note={t('modals.autoRefillFailure.note', { phoneNumber: getPhoneNumber({ isEnd: true }) })}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.easyRefillFailure.buttonText'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'AutoRefillNotVerified'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmit = useCallback(
        (formData) => {
            const { dobDay, dobMonth, dobYear, zipcode } = formData;
            setIsSubmitting(true);
            dispatch(
                autoRefillVerifyUserRoutine.trigger({
                    dateOfBirth: formatBirthDate(dobYear, dobMonth, dobDay),
                    zipcode: zipcode,
                    onSuccess: () => {
                        dispatch(refreshUnauthenticatedSession());
                    },
                    onFailure: (error: VerifyUserResponse) => {
                        setIsSubmitting(false);
                        handleShowErrorModal(error);
                    },
                    onMaxNumAttemptsReached: () => {
                        setIsSubmitting(false);
                        navigate('/verification-failed?flow=auto-refill');
                    }
                })
            );
        },
        [dispatch, handleShowErrorModal]
    );

    const autoRefillBearerToken = useSelector(autoRefillUserBearerTokenSelector);
    useEffect(() => {
        if (autoRefillBearerToken) {
            navigate('/auto-refill/prescriptions');
        }
    }, [autoRefillBearerToken]);

    return (
        <MarketingPageLayout backgroundImage={backgroundImage} metaData={{}}>
            <PageSection>
                <Container fluid className={`auto-refill`} id={'auto-refill'}>
                    <Row>
                        <Col sm="12" lg={{ span: 10, offset: 1 }}>
                            <Row>
                                <Col lg="8" className="auto-refill-form-container">
                                    <p className="h5 auto-refill-eyebrow text-left">
                                        {t('pages.autoRefill.userVerification.eyebrow')}
                                    </p>
                                    <h1 className="auto-refill-title text-left">
                                        {t('pages.autoRefill.userVerification.title')}
                                    </h1>

                                    <div className="spacer text-left mx-0 d-block">&nbsp;</div>

                                    <p className="h4 auto-refill-form-instructions text-left">
                                        {t('pages.autoRefill.userVerification.instructions')}
                                    </p>

                                    <AutoRefillForm
                                        onSubmit={(e) => handleSubmit(e)}
                                        formName="AutoRefillForm"
                                        submittingForm={isSubmitting}
                                    />
                                </Col>
                                <Col
                                    className="auto-refill-image-container"
                                    xs={{ span: 8, offset: 0 }}
                                    sm={{ span: 6, offset: 3 }}
                                    lg={{ span: 4, offset: 0 }}
                                >
                                    {autoRefillMan && (
                                        <GatsbyImage
                                            fluid={autoRefillMan?.childImageSharp?.fluid}
                                            image={getImage(autoRefillMan)}
                                            alt={'auto-refill-form-image'}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </PageSection>
        </MarketingPageLayout>
    );
};

export default AutoRefill;

export const query = graphql`
    query AutoRefillGetLanguageAndImageData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        autoRefillMan: file(relativePath: { eq: "assets/images/smiling-woman-with-backpack.png" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
